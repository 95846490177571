<template>
  <div>
    <div class="timesheet-entry">
      <div class="day">
        <div class="day__word">{{ dayOfWeek }}</div>
        <div class="day__number">{{ dayOfMonth }}</div>
      </div>
      <div class="timesheet-entry__content">
        <div
          v-for="(time, index) in allTimes"
          :key="index"
          class="timesheet-entry__content__time-item"
          @click="handleSelect(index)"
        >
          <TimeItem
            :key="times.length"
            :hideRemoveButton="hideRemoveButton"
            :isRemoving="time.id === itemToRemove"
            :activities="filteredActivities"
            :allActivities="activities"
            :time="time"
            :triggerEdit="triggerEdit"
            :hideAddTimeButton="hideAddTimeButton"
            :expectedTimes="expectedTimes"
            @updateActivity="updateSelectedPayRate"
            @addTime="addTimeToEntry"
            @removeTime="removeTimeFromEntry"
            @editing="handleEditing"
            @success="handleSuccess"
          />
        </div>
        <div
          class="my-5 d-flex align-center justify-center"
          v-if="isAddingTime"
        >
          <v-progress-circular size="30" indeterminate color="primary" />
        </div>
      </div>
    </div>
    <v-divider class="my-3" />
  </div>
</template>

<script>
import moment from "moment";
import TimeItem from "@/components/timesheets/time/TimeItem";
import { find, size, map, isEmpty } from "lodash";
import { ADD_ENTRY, REMOVE_ENTRY } from "@/store/modules/timesheets/actions";
import { createNamespacedHelpers } from "vuex";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { xor, filter, compact } from "lodash";

const { mapActions, mapState } = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  name: "TimesheetEntry",
  props: {
    date: {
      type: String,
      required: true
    },
    times: {
      type: Array,
      required: true
    },
    hoveredTimesheet: {
      type: Number,
      required: false
    },
    activities: {
      type: Array,
      required: true
    },
    // type string is used to trigger child components
    triggerEdit: String,
    expectedTimes: Object
  },
  components: {
    TimeItem
  },
  data() {
    return {
      showEmptyTemplate: false,
      selectedTime: null,
      isHovered: false,
      emptyEntry: {
        id: "",
        startTime: "",
        endTime: "",
        breakMinutes: 0,
        absent: false,
        break: null,
        activity: null
      },
      allTimes: this.times,
      isAddingTime: false,
      itemToRemove: null,
      selectedActivities: [],
      filteredActivities: []
    };
  },
  created() {
    this.setSelectedActivities();
  },
  computed: {
    ...mapState({
      isRemovingEntry: state => state.isRemovingEntry,
      currentTimesheet: state => state.currentTimesheet
    }),
    dayOfWeek() {
      return moment(this.date).format("ddd");
    },
    dayOfMonth() {
      return moment(this.date).format("DD");
    },
    currentTimesheetId() {
      return this.$route.params.id;
    },
    hideRemoveButton() {
      return size(this.allTimes) === 1 || this.isRemovingEntry;
    },
    hideAddTimeButton() {
      if (!this.isUnitTime) {
        return true;
      }
      let hasEmptyEntry = false;
      map(this.allTimes, time => {
        if (isEmpty(time.startTime) || isEmpty(time.endTime))
          hasEmptyEntry = true;
      });
      return hasEmptyEntry || size(this.times) === size(this.activities);
    },
    isUnitTime() {
      return this.currentTimesheet.timeUnitName === "hour";
    }
  },
  methods: {
    ...mapActions({
      addTime: ADD_ENTRY,
      removeItem: REMOVE_ENTRY
    }),
    handleSelect(item) {
      this.selectedTime = this.selectedTime === item ? null : item;
      this.$emit("select", this.id);
    },
    async addTimeToEntry() {
      this.isAddingTime = true;
      try {
        this.emptyEntry["pay-rates"] = this.filteredActivities[0];
        await this.addTime({
          ...this.emptyEntry,
          dayDate: this.date,
          timesheet: {
            id: this.currentTimesheetId
          }
        });
        this.selectedActivities = [
          ...this.selectedActivities,
          this.filteredActivities[0]
        ];
        this.filteredActivities = xor(this.activities, this.selectedActivities);
      } finally {
        this.isAddingTime = false;
      }
    },
    updateSelectedPayRate({ payRate, prevPayRate }) {
      if (prevPayRate) {
        this.selectedActivities = filter(
          this.selectedActivities,
          ({ id }) => id !== prevPayRate.id
        );
      }
      this.selectedActivities = [...this.selectedActivities, payRate];
      this.filteredActivities = xor(this.activities, this.selectedActivities);
    },
    async removeTimeFromEntry({ id }) {
      this.itemToRemove = id;
      try {
        await this.removeItem(id);
        this.setSelectedActivities();
      } finally {
        this.itemToRemove = null;
      }
    },
    handleEditing() {
      this.$emit("editing");
    },
    handleSuccess() {
      this.$emit("success");
    },
    setSelectedActivities() {
      this.selectedActivities = compact(
        map(this.times, time =>
          find(this.activities, {
            value: time.payRates.id
          })
        )
      );
      this.filteredActivities = xor(this.activities, this.selectedActivities);
    }
  },
  watch: {
    times(newValue) {
      this.allTimes = newValue;
    }
  }
};
</script>

<style lang="scss"></style>
