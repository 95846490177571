export const USER_MODEL = "user";
export const USER_STAFF_MODEL = "users/staff-account";
export const USERS_TO_IMPERSONATE = "users/to-impersonate";
export const USER_ONLY_FULL_NAME = "only-full-name";
export const INVITE_USER = "invite";
export const RESET_PASSWORD = "reset-password";

const userModel = {
  firstName: "",
  lastName: "",
  dob: "",
  email: "",
  phone: "",
  gender: "",
  mobile: "",
  fax: "",
  cv: "",
  website: "",
  awr: false,
  dda: false,
  password: "",
  countryOfBirth: "",
  incomeTypeName: "",
  ethnicGroupId: "",
  incomeType: "",
  isBilling: false,
  accountType: "",
  approverLevelNbr: "",
  utr: "",
  yearsReferenced: null,
  complianceSupportingFile: [],
  ethnicOrigin: {},
  userComplianceChecks: [],
  userReferences: [],
  roles: [],
  niNumber: null,
  status: "",
  reasons: [],
  compliance_requirements: [],
  agencyWorkerReference: "",
  clientWorkerReference: "",
  address: {
    line1: "",
    line2: "",
    line3: "",
    postCode: "",
    country: ""
  },
  agency_worker_reference: "",
  client_worker_reference: "",
  addresses: {
    jsonApi: "hasMany",
    type: "addresses"
  },
  organisations: {
    jsonApi: "hasMany",
    type: "organisations"
  },
  applications: {
    jsonApi: "hasMany",
    type: "applications"
  },
  organisation: {
    jsonApi: "hasOne",
    type: "organisations"
  },
  intermediaryOrganisation: {
    jsonApi: "hasOne",
    type: "organisations"
  },
  events: {
    jsonApi: "hasMany",
    type: "event"
  },
  complianceChecks: {
    jsonApi: "hasMany",
    type: "compliance-checks"
  },
  references: {
    jsonApi: "hasMany",
    type: "references"
  }
};

export const USER_SELF_MODEL = "self";

export default userModel;
