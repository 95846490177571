import { setStoreValue } from "@/store/utils";
import { filter } from "lodash";

export const SET_IS_FETCHING_CURRENT_TIMESHEET =
  "SET_IS_FETCHING_CURRENT_TIMESHEET";
export const SET_IS_FETCHING_TIMESHEETS = "SET_IS_FETCHING_TIMESHEETS";
export const SET_CURRENT_TIMESHEET = "SET_CURRENT_TIMESHEET";
export const SET_TIMESHEETS = "SET_TIMESHEETS";
export const SET_CURRENT_TIMESHEET_COMMENTS = "SET_CURRENT_TIMESHEET_COMMENTS";
export const SET_CURRENT_TIMESHEET_ENTRIES = "SET_CURRENT_TIMESHEET_ENTRIES";
export const SET_CURRENT_TIMESHEET_EXPENSES = "SET_CURRENT_TIMESHEET_EXPENSES";
export const SET_IS_FETCHING_CURRENT_TIMESHEET_COMMENTS =
  "SET_IS_FETCHING_CURRENT_TIMESHEET_COMMENTS";
export const SET_IS_FETCHING_CURRENT_TIMESHEET_ENTRIES =
  "SET_IS_FETCHING_CURRENT_TIMESHEET_ENTRIES";
export const SET_IS_FETCHING_CURRENT_TIMESHEET_EXPENSES =
  "SET_IS_FETCHING_CURRENT_TIMESHEET_EXPENSES";
export const SET_IS_REMOVING_EXPENSE = "SET_IS_REMOVING_EXPENSE";
export const SET_IS_REMOVING_ENTRY = "SET_IS_REMOVING_ENTRY";
export const SET_ENTRIES_TO_EDIT = "SET_ENTRIES_TO_EDIT";
export const SET_ENTRY = "SET_ENTRY";
export const SET_TIMESHEET_FILTERS = "SET_TIMESHEET_FILTERS";
export const SET_TIMESHEET_TO_VERIFY = "SET_TIMESHEET_TO_VERIFY";
export const SET_TIMESHEET_TO_APPROVE = "SET_TIMESHEET_TO_APPROVE";
export const SET_SHIFT_TIMESHEETS = "SET_SHIFT_TIMESHEETS";

const mutations = {
  [SET_TIMESHEETS]: setStoreValue("timesheets"),
  [SET_TIMESHEET_FILTERS]: setStoreValue("timesheetFilters"),
  [SET_CURRENT_TIMESHEET]: setStoreValue("currentTimesheet"),
  [SET_IS_FETCHING_TIMESHEETS]: setStoreValue("isFetchingTimesheets"),
  [SET_IS_FETCHING_CURRENT_TIMESHEET]: setStoreValue(
    "isFetchingCurrentTimesheet"
  ),
  [SET_CURRENT_TIMESHEET_COMMENTS]: setStoreValue("timesheetComments"),
  [SET_CURRENT_TIMESHEET_EXPENSES]: setStoreValue("timesheetExpenses"),
  [SET_CURRENT_TIMESHEET_ENTRIES]: setStoreValue("timesheetEntries"),
  [SET_IS_FETCHING_CURRENT_TIMESHEET_COMMENTS]: setStoreValue(
    "isFetchingTimesheetComments"
  ),
  [SET_IS_FETCHING_CURRENT_TIMESHEET_ENTRIES]: setStoreValue(
    "isFetchingTimesheetEntries"
  ),
  [SET_IS_FETCHING_CURRENT_TIMESHEET_EXPENSES]: setStoreValue(
    "isFetchingTimesheetExpenses"
  ),
  [SET_IS_REMOVING_EXPENSE]: setStoreValue("isRemovingExpense"),
  [SET_IS_REMOVING_ENTRY]: setStoreValue("isRemovingEntry"),
  [SET_ENTRIES_TO_EDIT]: setStoreValue("entriesToEdit"),
  [SET_ENTRY](state, { editedEntry, id }) {
    const currentEntries = filter(
      state.timesheetEntries,
      entry => entry.id !== id
    );
    state.timesheetEntries = [...currentEntries, editedEntry];
  },
  [SET_TIMESHEET_TO_VERIFY]: setStoreValue("timesheetsToVerify"),
  [SET_TIMESHEET_TO_APPROVE]: setStoreValue("timesheetsToApprove"),
  [SET_SHIFT_TIMESHEETS]: setStoreValue("shiftTimesheets")
};

export default mutations;
