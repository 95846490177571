<template>
  <div class="candidates-wrapper">
    <div>
      <div class="container col-lg-12 col-sm-12 candidates">
        <div v-if="isFetchingData">
          <v-skeleton-loader v-for="i in 15" :key="i" type="list-item-avatar" />
        </div>
        <div v-else-if="isNotEmpty" class="row">
          <div
            class="col-12 candidate-list"
            :class="{ 'col-md-4': showDetails, 'with-details': showDetails }"
          >
            <div class="candidates-toolbar">
              <DynamicSearchBar @search="onSearch" />
            </div>
            <div
              :class="{ 'mt-4': index > 0 }"
              v-for="(applications, groupsKey, index) in applicationsToDisplay"
              :key="index"
            >
              <v-divider v-if="index > 0" class="mb-2" />
              <div class="status">
                <CandidateStatusHeader
                  :status="groupsKey"
                  :size="applications.length"
                />
              </div>
              <div
                v-for="(application, index) in applications"
                :key="index"
                @click="selectApplication(application)"
                :class="{
                  'worker-selected': isSelected(application)
                }"
                class="worker-wrapper multiselect-wrapper"
              >
                <v-checkbox
                  class="checkbox-large-icon"
                  size="2rem"
                  v-if="selectMultiple"
                  :input-value="isSelected(application)"
                />
                <div class="icon-wrapper" v-else @click="triggerCheckboxMode">
                  <v-icon>mdi-account-outline</v-icon>
                </div>
                <div>
                  {{ candidate(application) }}
                </div>
              </div>
            </div>
          </div>
          <CandidateDetailsDesktop
            :key="detailsKey"
            v-if="showDetails && !isMobile"
            class="col-12 col-md-8"
            :application="first(selectedApplications)"
          />
          <CandidateDetailsMobile
            :key="detailsKey"
            v-else-if="showDetails"
            :application="first(selectedApplications)"
            @close="selectedApplications = []"
          />
        </div>
        <EmptyStates
          v-else
          icon="applications-empty-state"
          description="No applications have been made yet"
        />
      </div>
      <slot name="action" />
    </div>
    <BaseFooter class="relative">
      <MultipleSelectAction
        v-show="selectMultiple"
        :selectedCount="selectedCount"
        :isSelectAll="isSelectAll"
        :items="categories"
        @selectAll="handleSelectAll"
        @cancel="resetSelection"
      />
    </BaseFooter>
    <BaseFooter v-if="selectedApplications.length && !isFetchingData">
      <ExpandedButtons>
        <div
          class="permissioned-container actions-bar"
          :class="{ vertical: isMobile }"
        >
          <WithdrawCandidateAction
            v-if="checkIfSelectedApplicationsArePermitted('withdraw')"
            :applicationIds="selectedApplicationsIds"
            @statusChanged="refreshView"
          />
          <RejectCandidateAction
            v-if="
              checkIfSelectedApplicationsArePermitted('reject') &&
                !isSomeApplicationAllocated
            "
            :applications="selectedApplications"
            @statusChanged="refreshView"
          />
          <AddAssessmentAction
            v-if="checkIfSelectedApplicationsArePermitted('add-assessment')"
            :applications="selectedApplications"
          />
          <RequestInterviewAction
            v-if="checkIfSelectedApplicationsArePermitted('request-interview')"
            :applicationsIds="selectedApplicationsIds"
          />
          <ApproveCandidateAction
            v-if="checkIfSelectedApplicationsArePermitted('approve')"
            :applicationsIds="selectedApplicationsIds"
            @statusChanged="refreshView"
          />
          <ShortlistCandidateAction
            v-if="checkIfSelectedApplicationsArePermitted('shortlist')"
            :applicationsIds="selectedApplicationsIds"
            @statusChanged="refreshView"
          />
          <AllocateCandidateAction
            v-if="
              checkIfSelectedApplicationsArePermitted('allocate') &&
                !isSomeApplicationAllocated
            "
            :applications="selectedApplications"
            :shifts="bookingShifts"
            @allocated="refreshView"
          />
          <ConfirmCandidateAllocationAction
            v-if="checkCanConfirmAllocation() && isEveryApplicationAllocated"
            :applicationIds="selectedApplicationsIds"
            @confirmed="refreshView"
          />
        </div>
      </ExpandedButtons>
    </BaseFooter>
  </div>
</template>

<script>
import { every, filter, first, find, map, size, some, toLower } from "lodash";
import BaseFooter from "@/components/common/BaseFooter";
import WithdrawCandidateAction from "@/views/bookings/components/actions/WithdrawCandidateAction";
import RejectCandidateAction from "@/views/bookings/components/actions/RejectCandidateAction";
import AddAssessmentAction from "@/views/bookings/components/actions/AddAssessmentAction";
import RequestInterviewAction from "@/views/bookings/components/actions/RequestInterviewAction";
import ApproveCandidateAction from "@/views/bookings/components/actions/ApproveCandidateAction";
import ShortlistCandidateAction from "@/views/bookings/components/actions/ShortlistCandidateAction";
import AllocateCandidateAction from "@/views/bookings/components/actions/AllocateCandidateAction";
import ConfirmCandidateAllocationAction from "@/views/bookings/components/actions/ConfirmCandidateAllocationAction";
import { createNamespacedHelpers } from "vuex";
import {
  BOOKINGS_NAMESPACE,
  FETCH_CURRENT_BOOKING_CANDIDATES,
  FETCH_BOOKING_SHIFTS
} from "@/store/modules/bookings/actions";
import {
  GET_CURRENT_BOOKING_CANDIDATES,
  IS_LOADING_BOOKING_CANDIDATES,
  GET_BOOKING_SHIFTS
} from "@/store/modules/bookings/getters";
import ExpandedButtons from "@/components/common/ExpandedButtons";
import { isPermissioned } from "@/utils/permissions";
import EmptyStates from "@/components/common/EmptyStates";
import DynamicSearchBar from "@/components/common/DynamicSearchBar";
import CandidateDetailsDesktop from "@/views/bookings/BookingDetails/components/CandidateDetails/CandidateDetailsDesktop";
import CandidateDetailsMobile from "@/views/bookings/BookingDetails/components/CandidateDetails/CandidateDetailsMobile";
import {
  getGroupedAndSortedApplications,
  checkAllocatedApplication,
  checkConfirmedApplicationShifts,
  missingComplianceChecks
} from "@/utils/applications";
import generateId from "uuid/v4";
import { getFullName } from "@/utils/users";
import CandidateStatusHeader from "@/components/bookings/CandidateStatusHeader";
import MultipleSelectAction from "@/components/common/Button/MultipleSelectAction";

const { mapActions, mapGetters, mapState } = createNamespacedHelpers(
  BOOKINGS_NAMESPACE
);

export default {
  name: "Candidates",
  components: {
    ShortlistCandidateAction,
    ApproveCandidateAction,
    RequestInterviewAction,
    AddAssessmentAction,
    RejectCandidateAction,
    WithdrawCandidateAction,
    BaseFooter,
    ExpandedButtons,
    EmptyStates,
    DynamicSearchBar,
    CandidateDetailsDesktop,
    CandidateDetailsMobile,
    CandidateStatusHeader,
    MultipleSelectAction,
    AllocateCandidateAction,
    ConfirmCandidateAllocationAction
  },
  props: {
    submittedWorkerIds: Array
  },
  created() {
    this.fetchApplications({ onInit: true });
  },
  data() {
    return {
      selectedApplications: [],
      selectedApplication: null,
      selectedFilter: null,
      isFetchingData: false,
      selectMultiple: false,
      hovered: false,
      searchText: "",
      detailsKey: generateId(),
      isSelectedByStatus: {}
    };
  },
  computed: {
    ...mapGetters({
      allApplications: GET_CURRENT_BOOKING_CANDIDATES,
      isLoadingBookingCandidates: IS_LOADING_BOOKING_CANDIDATES,
      bookingShifts: GET_BOOKING_SHIFTS
    }),
    ...mapState({
      currentBooking: state => state.currentBooking
    }),
    applicationsToDisplay() {
      return getGroupedAndSortedApplications(this.searchedApplications);
    },
    showDetails() {
      return this.selectedApplications.length === 1 && !this.selectMultiple;
    },
    searchedApplications() {
      const searchableAttributes = ["firstName", "lastName"];
      return filter(this.allApplications, application =>
        some(searchableAttributes, attribute =>
          toLower(application.user[attribute]).includes(
            toLower(this.searchText)
          )
        )
      );
    },
    applicationStatuses() {
      return this.searchedApplications
        .map(({ status }) => ({
          status
        }))
        .filter(Boolean);
    },
    currentBookingId() {
      return this.$route.params.id;
    },
    isNotEmpty() {
      return size(this.searchedApplications);
    },
    selectedApplicationsIds() {
      return map(this.selectedApplications, "id");
    },
    externalSelectedApplication() {
      const { applicationId } = this.$route.query;
      if (applicationId) {
        return find(this.filteredApplications, { id: applicationId });
      }
      return null;
    },
    filteredApplications() {
      return filter(this.allApplications, "status");
    },
    selectedCount() {
      return size(this.selectedApplications);
    },
    isSelectAll() {
      return this.isNotEmpty === this.selectedCount;
    },
    categories() {
      return Object.keys(this.applicationsToDisplay);
    },
    isSomeApplicationAllocated() {
      return some(this.selectedApplications, application => {
        return checkAllocatedApplication(application.shifts);
      });
    },
    isEveryApplicationAllocated() {
      return every(this.selectedApplications, application => {
        return (
          checkAllocatedApplication(application.shifts) &&
          !missingComplianceChecks(
            this.currentBooking.complianceRequirements || [],
            application?.complianceChecks || []
          ).length
        );
      });
    },
    isSomeApplicationConfirmed() {
      return some(this.selectedApplications, application => {
        return checkConfirmedApplicationShifts(application.shifts);
      });
    }
  },
  methods: {
    ...mapActions({
      fetchCurrentBookingApplications: FETCH_CURRENT_BOOKING_CANDIDATES,
      fetchBookingShifts: FETCH_BOOKING_SHIFTS
    }),
    onSearch(text) {
      this.searchText = text;
    },
    async fetchApplications({ onInit }) {
      this.isFetchingData = true;
      this.fetchBookingShifts({
        id: this.currentBookingId
      });
      await this.fetchCurrentBookingApplications(this.currentBookingId);
      this.selectInitialApplication(onInit);
      this.initializeIsSelectedByStatus();
      this.isFetchingData = false;
    },
    isSelected(application) {
      return some(this.selectedApplications, ({ id }) => {
        return id === application.id;
      });
    },
    selectFilter(filter) {
      this.selectedFilter = filter;
      if (!this.isMobile) {
        this.selectedApplication = first(this.applicationsToDisplay);
      }
    },
    selectApplication(application) {
      const isAppSelected = this.isSelected(application);
      if (isAppSelected && this.selectMultiple) {
        this.selectedApplications = filter(
          this.selectedApplications,
          ({ id }) => id !== application.id
        );
      } else if (!isAppSelected) {
        this.selectedApplications = [
          ...(this.selectMultiple ? this.selectedApplications : []),
          application
        ].filter(Boolean);
        // refresh details component
        this.detailsKey = generateId();
      }
    },
    setSelectedApplicationFromSubmitted(applications) {
      const foundApplication = applications.find(application =>
        application.user
          ? application.user.id === first(this.submittedWorkerIds)
          : null
      );
      this.selectedApplication = foundApplication || null;
    },
    checkIfSelectedApplicationsArePermitted(permission) {
      return every(this.selectedApplications, application => {
        return (
          isPermissioned(`applications[${application.id}].${permission}`) &&
          !this.isSomeApplicationConfirmed
        );
      });
    },
    checkCanConfirmAllocation() {
      return isPermissioned(`application-shifts.confirm`);
    },
    triggerCheckboxMode() {
      this.selectedApplications = [];
      this.selectMultiple = true;
    },
    handleSelectAll(status) {
      if (status) {
        this.toggleSelectAll(status);
      } else {
        this.selectedApplications = [...this.searchedApplications];
      }
    },
    resetSelection() {
      this.selectedApplications = [];
      this.selectInitialApplication();
      this.initializeIsSelectedByStatus();
      this.selectMultiple = false;
    },
    async toggleSelectAll(status) {
      this.isSelectedByStatus[status] = !this.isSelectedByStatus[status];
      map(this.applicationsToDisplay[status], application => {
        if (this.isSelectedByStatus[status]) {
          if (!this.isSelected(application)) {
            this.selectedApplications.push(application);
          }
        } else {
          this.selectedApplications = filter(
            this.selectedApplications,
            ({ id }) => id !== application.id
          );
        }
      });
    },
    selectInitialApplication(onInit) {
      if (!this.isMobile) {
        const isLink = onInit && this.externalSelectedApplication;
        const applicationToSelect = isLink
          ? this.externalSelectedApplication
          : first(this.filteredApplications);
        this.selectApplication(applicationToSelect);
      }
    },
    initializeIsSelectedByStatus() {
      map(
        this.applicationsToDisplay,
        (applications, status) => (this.isSelectedByStatus[status] = false)
      );
    },
    candidate(application) {
      return this.showCandidateName(application)
        ? getFullName(application.user)
        : `Candidate ${application.id}`;
    },
    showCandidateName(application) {
      const { user } = application;
      return user && Boolean(user.firstName) && Boolean(user.lastName);
    },
    refreshView() {
      this.resetSelection();
      this.fetchApplications({ onInit: false });
    },
    first
  },
  provide() {
    return {
      applicationId: this.selectedApplication && this.selectedApplication.id
    };
  },
  watch: {
    applicationsToDisplay() {
      // refresh application details on application update
      if (size(this.selectedApplications) === 1) {
        this.selectedApplications = filter(
          this.allApplications,
          application => {
            if (application.id === first(this.selectedApplications).id) {
              return application;
            }
          }
        );
      }
    }
  }
};
</script>

<style lang="scss">
.candidates-wrapper {
  .icon-wrapper {
    border: 2px solid rgba(94, 122, 141, 0.1);
    &:hover {
      border: 2px solid rgb(100, 99, 99);
    }
  }
}
</style>
