import APIService, { withPrefix } from "@/services/APIService";
import JSONApiService from "@/services/JSONApiService";
import {
  AGENCY,
  APPLICATION_SHIFTS_USERS,
  APPLICATIONS,
  APPROVER,
  ASSESSMENTS,
  AUTHOR,
  BOOKING_APPROVERS,
  CAUSER,
  COMMENTS,
  COMPLIANCE_CHECKS,
  include,
  LOCATION,
  MANAGER,
  nested,
  PAY_RATE_ITEMS,
  PAY_RATES,
  PUBLISHING_SCHEDULE,
  SHIFT_PATTERNS,
  USER,
  VERIFIER,
  REFERENCES,
  CREATED_BY,
  ROOT_CLIENT,
  CLIENT,
  QUOTAS,
  BOOKING,
  APPLICATION_SHIFTS_USER_AGENCY,
  PATTERN,
  APPLICATION_USER_ORGANISATION,
  SHIFTS,
  APPLICATION_SHIFTS,
  SHIFT
} from "@/models/relationships";
import { BOOKING_MODEL, UPDATE_PO_NUMBER } from "@/models/booking-model";
import { COMMENT_MODEL } from "@/models/comment-model";
import { SHIFT_PATTERN_MODEL } from "@/models/shift-patterns-model";
import { disablePluralizeForCall } from "@/services/utils";
import { SHIFT_MODEL } from "@/models/shift-model";
import { applicationActions } from "@/models/application-model";
import { EVENT_MODEL } from "@/models/event-model";
import { PUBLISHING_SCHEDULE_MODEL } from "@/models/publishing-schedule-model";
import { ORGANISATION_MODEL } from "@/models/organisation-model";
import { isNotTestingEnv } from "@/services/utils";
import pluralize from "pluralize";
import { BUDGET_MODEL } from "@/models/budget-model";
import { QUOTA_MODEL } from "@/models/quota-model";
import { getToken } from "@/services/utils";

// TODO Remove the condition if all the cycle imports will be fixed
const API = isNotTestingEnv() && withPrefix(APIService.client(), "bookings");

const bookingIncludes = include(
  LOCATION,
  COMMENTS,
  SHIFT_PATTERNS,
  APPLICATIONS,
  PUBLISHING_SCHEDULE,
  PAY_RATES,
  MANAGER,
  VERIFIER,
  APPROVER,
  BOOKING_APPROVERS,
  CLIENT,
  ROOT_CLIENT,
  nested(APPLICATIONS, COMPLIANCE_CHECKS)
);

export const applicationIncludes = include(
  USER,
  COMPLIANCE_CHECKS,
  REFERENCES,
  ASSESSMENTS,
  ORGANISATION_MODEL,
  nested(ASSESSMENTS, CREATED_BY),
  SHIFTS,
  nested(SHIFTS, SHIFT),
  nested(SHIFTS, nested(SHIFT, APPLICATION_SHIFTS))
);

const callBookingAction = ({ bookingId, action, commit }) => {
  const api = JSONApiService(commit);
  api.one(BOOKING_MODEL, bookingId);
  disablePluralizeForCall(api, () => api.all(action));
  return api.post({}, bookingIncludes);
};

const callShiftPatternAction = ({ id, action, commit }) => {
  const api = JSONApiService(commit);
  api.one(SHIFT_PATTERN_MODEL, id);
  disablePluralizeForCall(api, () => api.all(action));
  return action === "delete" ? api.post({}) : api.patch({});
};

const callEditBookingAction = ({ bookingId, action, data, commit }) => {
  const api = JSONApiService(commit);
  api.one(BOOKING_MODEL, bookingId);
  disablePluralizeForCall(api, () => api.all(action));
  return api.patch(data, bookingIncludes);
};

const bookingApi = ({ commit } = {}) => ({
  submitForApproval: async bookingId =>
    callBookingAction({ bookingId, action: "submit-for-approval", commit }),
  publishBooking: async (bookingId, unitId) => {
    const api = JSONApiService(commit);
    api.one(BOOKING_MODEL, bookingId);
    api.one("publishing-schedule", unitId);
    disablePluralizeForCall(api, () => {
      api.all("publish");
    });
    return api.post({}, bookingIncludes);
  },
  acceptBooking: async bookingId =>
    callBookingAction({ bookingId, action: "accept", commit }),
  approveBooking: async bookingId =>
    callBookingAction({ bookingId, action: "approve", commit }),
  cancelBooking: async bookingId =>
    callBookingAction({ bookingId, action: "cancel", commit }),
  rejectBooking: async bookingId =>
    callBookingAction({ bookingId, action: "reject", commit }),
  reopenBooking: async bookingId =>
    callBookingAction({ bookingId, action: "reopen", commit }),
  cancelShift: async ({ bookingId, shiftId }) => {
    const api = JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .one(SHIFT_MODEL, shiftId);
    disablePluralizeForCall(api, () => api.all("cancel"));
    return api.post({}, bookingIncludes);
  },
  cancelShifts: async ({ bookingId, shiftIds }) => {
    const api = JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(SHIFT_MODEL);
    disablePluralizeForCall(api, () => api.all("bulk-cancel"));
    return api.post({ shiftIds }, bookingIncludes);
  },
  deleteBooking: async bookingId => API.delete(`${bookingId}`),

  createBooking: async bookingData =>
    JSONApiService(commit).create(BOOKING_MODEL, bookingData, bookingIncludes),
  createShift: async ({ bookingId, shift }) =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(SHIFT_PATTERN_MODEL)
      .post(shift),
  editBooking: async (bookingId, editedBooking) =>
    JSONApiService(commit).update(
      BOOKING_MODEL,
      {
        id: bookingId,
        ...editedBooking
      },
      bookingIncludes
    ),
  editBookingPONumber: async (bookingId, data) =>
    callEditBookingAction({
      bookingId,
      action: UPDATE_PO_NUMBER,
      data,
      commit
    }),
  getBooking: async bookingId =>
    JSONApiService(commit).find(BOOKING_MODEL, bookingId, bookingIncludes),
  getBookingPayRates: async (bookingId, params) =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(PAY_RATES)
      .get({ ...(params ? params : include(PAY_RATE_ITEMS)) }),
  editBookingPayRates: async (bookingId, editedPayRates) =>
    API.patch(`${bookingId}/pay-rates?include=pay-rates.pay-rate-items`, {
      data: editedPayRates
    }),
  editBookingTimesheetApprover: async (bookingId, editedApprover) =>
    callEditBookingAction({
      bookingId,
      action: "timesheet-approver",
      data: editedApprover,
      commit
    }),
  editBookingTimesheetVerifier: async (bookingId, editedVerifier) =>
    callEditBookingAction({
      bookingId,
      action: "timesheet-verifier",
      data: editedVerifier,
      commit
    }),
  getBookingComments: async bookingId =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(COMMENT_MODEL)
      .get(include(AUTHOR)),
  getBookingEvents: async bookingId =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(EVENT_MODEL)
      .get(include(CAUSER)),
  getBookingShiftPatterns: async bookingId =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(SHIFT_PATTERN_MODEL)
      .get(include(PAY_RATES)),
  addBookingComment: async (bookingId, comment) =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .create(COMMENT_MODEL, comment),
  getBookingShifts: async ({ id, params = {} }) =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, id)
      .all(SHIFT_MODEL)
      .get({
        ...params,
        ...include(
          APPLICATION_SHIFTS_USERS,
          BOOKING,
          PATTERN,
          nested(PATTERN, PAY_RATES),
          APPLICATION_USER_ORGANISATION
        )
      }),
  getBookingShiftsV2: async ({ id, params = {} }) =>
    APIService.client().getV2(`bookings/${id}/shifts`, { params }),
  getBookings: async params =>
    JSONApiService(commit)
      .all(BOOKING_MODEL)
      .get({
        ...params,
        ...include(LOCATION, ROOT_CLIENT, CLIENT, SHIFT_PATTERNS)
      }),
  getBookingCandidates: async ({ bookingId, params, includes }) =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(applicationActions.APPLICATIONS)
      .get({ ...params, ...includes }),
  getBookingPublishingSchedule: async bookingId =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(PUBLISHING_SCHEDULE_MODEL)
      .get(include(AGENCY, QUOTAS)),
  deleteShiftPatterns: async bookingId =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .all(SHIFT_PATTERN_MODEL)
      .destroy(),
  submitShiftPattern: async id =>
    callShiftPatternAction({ id, action: "submit", commit }),
  rejectShiftPattern: async id =>
    callShiftPatternAction({ id, action: "reject", commit }),
  approveShiftPattern: async id =>
    callShiftPatternAction({ id, action: "approve", commit }),
  deleteShiftPattern: async id =>
    JSONApiService(commit)
      .one(SHIFT_PATTERN_MODEL, id)
      .destroy(),
  createBookingFile: async (id, data) =>
    APIService.client().postFile(
      `${pluralize(BOOKING_MODEL)}/${id}/file`,
      data
    ),
  getBudgets: async params =>
    JSONApiService(commit)
      .all(BUDGET_MODEL)
      .get(params),
  createBudget: async data => JSONApiService(commit).create(BUDGET_MODEL, data),
  editBudget: async data => JSONApiService(commit).update(BUDGET_MODEL, data),
  createQuota: async ({ scheduleId, data }) =>
    JSONApiService(commit)
      .all(QUOTA_MODEL)
      .post({ ...data, booking_publishing_schedule_id: scheduleId }),
  editQuota: async ({ data }) =>
    JSONApiService(commit)
      .one(QUOTA_MODEL, data.id)
      .patch(data),
  deleteQuota: async ({ bookingId, scheduleId, quotaId }) =>
    JSONApiService(commit)
      .one(BOOKING_MODEL, bookingId)
      .one(PUBLISHING_SCHEDULE_MODEL, scheduleId)
      .one(QUOTA_MODEL, quotaId)
      .destroy(),
  getShifts: async params =>
    JSONApiService(commit)
      .all(SHIFT_MODEL)
      .get({
        ...params,
        ...include(
          APPLICATION_SHIFTS_USERS,
          APPLICATION_SHIFTS_USER_AGENCY,
          BOOKING_MODEL,
          QUOTAS,
          nested(BOOKING_MODEL, LOCATION),
          nested(BOOKING_MODEL, PUBLISHING_SCHEDULE),
          nested(BOOKING_MODEL, nested(PUBLISHING_SCHEDULE, AGENCY)),
          nested(BOOKING_MODEL, nested(PUBLISHING_SCHEDULE, QUOTAS)),
          nested(BOOKING_MODEL, CLIENT),
          nested(PATTERN, PAY_RATES)
        )
      }),
  printableTimesheetPdf: async ({ shifts }) =>
    APIService.client()
      .post(`/shifts/printable-timesheet`, {
        data: { attributes: { shifts } }
      })
      .then(responsePost => {
        const token = `token=${getToken()}`;
        APIService.client()
          .get(`/shifts/printable-timesheet/get-timesheet-file/`, {
            params: {
              filename: responsePost.fileName,
              extension: responsePost.extension
            },
            headers: { Authorization: `Bearer ${token}` },
            responseType: "blob"
          })
          .then(response => {
            const url = window.URL.createObjectURL(
              new Blob([response], { type: response["type"] })
            );
            const link = document.createElement("a");
            link.href = url;
            const prefix = "timesheets_";
            const fileName =
              response["type"] === "application/pdf"
                ? `${prefix}${responsePost.fileDate}.pdf`
                : `${prefix}${responsePost.fileName}.zip`;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          })
          .catch(error => console.log(error));
      }),
  editShift: async data => JSONApiService(commit).update(SHIFT_MODEL, data),
  submitAndConfirmWorker: async ({ bookingId, shiftId, worker }) => {
    return await APIService.client().post(
      `/bookings/${bookingId}/shifts/${shiftId}/submit-and-confirm-worker`,
      {
        data: {
          attributes: {
            ...worker,
            first_name: worker.firstName,
            last_name: worker.lastName
          }
        }
      }
    );
  },
  createBulk: async ({ data }) => {
    return await APIService.client().post(`/bookings/bulk-create`, {
      data
    });
  }
});

export default bookingApi;
