<template>
  <div>
    <div class="pb-3" v-if="showFiles">
      <FileComponent
        :isRemoving="isLoading"
        class="py-2"
        v-for="file in allFiles"
        :key="file.id"
        :customActions="customActions"
        :disablePreview="disableAutoProceed || disablePreview"
        :file="file"
        :disabled="disabled"
        @removeFile="handleRemoveFile(file)"
        @show="onShow"
      />
    </div>
    <label
      class="file-select"
      id="uppy"
      v-show="isMultiple || !allFiles.length"
    >
      <div class="upload-cover" v-show="!isUploading">
        <div class="file-upload-icon" :class="{ disabled: disabled }">
          <v-img
            src="@/assets/svg/file/empty-file.svg"
            class="file-icon"
            alt="File Icon"
          />
        </div>
        <div>
          <div class="file-upload-title" :class="{ disabled: disabled }">
            Upload File
          </div>
          <div class="file-upload-subtitle">
            {{ hintText }}
          </div>
        </div>
      </div>
      <div class="uppy-input" :id="`item-${id}`"></div>
      <div v-if="showValidationErrors && !allFiles.length" class="text-error">
        This field is required
      </div>
      <div class="pt-3" :id="`progress-${id}`"></div>
    </label>
  </div>
</template>

<script>
import Uppy from "@uppy/core";
import FileInput from "@uppy/file-input";
import StatusBar from "@uppy/status-bar";
import XHRUpload from "@uppy/xhr-upload";
import Tus from "@uppy/tus";
import "@uppy/core/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import { mapActions } from "vuex";
import { getToken, getImpersonateUserId } from "@/services/utils";

import FileComponent from "@/components/common/FileComponent";

export default {
  props: {
    /**
     * A site-wide unique ID for the uppy instance
     * If multiple Uppy instances are being used, for instance, on two different pages, an id should be specified.
     * @example
     * "user_avatar"
     */
    id: String,
    isMultiple: {
      type: Boolean,
      required: false,
      default: false
    },
    limit: {
      type: Number,
      required: false,
      default: 6 // default 1MB
    },
    /**
     * Minimum number of files
     */
    min: {
      type: Number,
      required: false,
      default: null // default unset
    },
    /**
     * Maximum number of files
     */
    max: {
      type: Number,
      required: false,
      default: null // default unset
    },
    /**
     * Array of allowed file types
     * exact mime types image/jpeg, or file extensions .jpp
     * @example
     * ['image/*', '.jpg', '.jpeg', '.png', '.gif']
     */
    fileTypes: {
      type: Array,
      required: false,
      default: null
    },
    files: {
      type: Array,
      required: false,
      default: () => []
    },
    fullWidth: Boolean,
    customActions: Boolean,
    uploadingUrl: String,
    isLoading: Boolean,
    disableAutoProceed: Boolean,
    showValidationErrors: Boolean,
    disabled: Boolean,
    disablePreview: Boolean
  },
  components: {
    FileComponent
  },
  data() {
    return {
      currentFile: null,
      allFiles: this.files,
      isUploading: false
    };
  },
  computed: {
    showFiles() {
      return this.allFiles.length > 0;
    },
    hintText() {
      if (this.fileTypes) {
        return `Must be ${this.fileTypes} and less than ${this.limit} MB`;
      }

      return `Must be less than ${this.limit} MB`;
    }
  },
  mounted() {
    if (!this.disabled) {
      this.adjustUppyInstance();
    }
  },
  methods: {
    ...mapActions({
      postGlobalMessage: POST_GLOBAL_MESSAGE
    }),
    addFile(file) {
      if (this.disableAutoProceed) {
        this.allFiles = [...this.allFiles, file];
        this.$emit("selectFile", {
          uppyInstance: this.uppy,
          file
        });
      }
    },
    adjustUppyInstance() {
      const uppy = new Uppy({
        id: this.id,
        debug: true,
        autoProceed: !this.disableAutoProceed,
        allowMultipleUploads: this.isMultiple,
        restrictions: {
          maxFileSize: this.limit * 1024 * 1024,
          maxNumberOfFiles: this.isMultiple ? this.max : 1,
          minNumberOfFiles: this.min,
          allowedFileTypes: this.fileTypes
        },
        locale: {
          strings: {
            youCanOnlyUploadX: {
              0: "You can only upload %{smart_count} file",
              1: "You can only upload %{smart_count} files"
            },
            youHaveToAtLeastSelectX: {
              0: "You have to select at least %{smart_count} file",
              1: "You have to select at least %{smart_count} files"
            },
            exceedsSize: "The maximum size alllowed is",
            youCanOnlyUploadFileTypes:
              "The file you selected is not supported format"
          }
        }
      })
        .use(FileInput, { target: `#item-${this.id}`, pretty: false })
        .use(StatusBar, {
          hideCancelButton: this.disableAutoProceed,
          target: `#progress-${this.id}`,
          hideUploadButton: this.customActions,
          hideAfterFinish: true
        });
      if (this.customActions) {
        const token = getToken();
        const impersonateUserId = getImpersonateUserId();
        const headers = {
          Authorization: `Bearer ${token}`
        };
        if (impersonateUserId) {
          headers["X-Impersonate"] = impersonateUserId;
        }
        uppy.use(XHRUpload, {
          fieldName: "file",
          endpoint: this.uploadingUrl,
          method: "post",
          headers,
          limit: 10
        });
      } else {
        // should be removed when all the views will have implemented custom actions
        uppy.use(Tus, { endpoint: "https://master.tus.io/files/", limit: 10 });
      }
      uppy
        .on("upload", this.onUpload)
        .on("file-added", this.addFile)
        .on("upload-success", this.onSuccess)
        .on("file-removed", this.onComplete)
        .on("complete", () => {
          this.onComplete();
          uppy.reset();
        })
        .on("restriction-failed", this.onRestrictionFailed)
        .on("reset-progress", this.onResetProgress)
        .on("upload-error", this.onUploadError);
      this.uppy = uppy;
    },
    onSuccess(file, response) {
      if (this.customActions) {
        file = response.body.data;
      } else {
        // should be removed when all the views will have implemented custom actions
        file.url = response.uploadURL;
      }
      this.allFiles = [...this.allFiles, file];
      this.isUploading = false;
      this.$emit("onUploadSuccess", this.allFiles);
    },
    onUpload() {
      this.isUploading = true;
      if (!this.isMultiple) {
        this.allFiles = [];
      }
    },
    onComplete() {
      this.isUploading = false;
    },
    onUploadError(file) {
      const message = {
        snackbar: true,
        type: "error",
        title: `An error occurred uploading file ${file.name}`
      };
      this.postGlobalMessage(message);
    },
    onRestrictionFailed(file, err) {
      const message = {
        snackbar: true,
        type: "error",
        title: err
      };
      this.postGlobalMessage(message);
    },
    handleRemoveFile(file) {
      if (this.customActions && !this.disableAutoProceed) {
        this.$emit("removeFile", { file });
      }
      if (this.disableAutoProceed) {
        this.$emit("unselectFile", { uppyInstance: this.uppy, file });
      }
    },
    onShow(file) {
      this.$emit("show", file);
    }
  },
  watch: {
    files(newValue) {
      this.allFiles = newValue;
    }
  }
};
</script>
