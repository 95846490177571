<template>
  <div class="submit-worker-details">
    <Permissioned section="applications.view" vertical>
      <div class="d-flex justify-space-between">
        <Permissioned section="workers" class="mb-2">
          <PrimaryButton
            permission="view"
            light
            @click="viewWorkerProfile"
            dense
            shrink
          >
            View Full Profile
          </PrimaryButton>
        </Permissioned>
      </div>
      <BaseDetails class="mb-4" title="Agency Supplier" v-if="organisation">
        {{ organisation.name }}
      </BaseDetails>
      <BaseDetails class="mb-4" title="Income Type" permission="income-type">
        <Chip shrink>{{ worker.incomeType }}</Chip>
      </BaseDetails>
      <BaseDetails class="mb-4" title="AWR Status" permission="awr-status">
        <template v-slot:header>
          <div class="d-flex flex-fill justify-space-between">
            <h3>AWR Status</h3>
            <Permissioned section="applications" :id="application.id">
              <PrimaryButton
                permission="edit.awr-status"
                light
                dense
                shrink
                @click.native="editAwrStatus = true"
              >
                Edit
                <template v-slot:append:icon>
                  <v-icon>mdi-pencil</v-icon>
                </template>
              </PrimaryButton>
            </Permissioned>
          </div>
        </template>
        <Chip shrink>{{ application.awr | awr }}</Chip>
        <EditAwrStatus
          :key="application.id"
          :application="application"
          :isOpen="editAwrStatus"
          @close="editAwrStatus = false"
        />
      </BaseDetails>
      <BaseDetails permission="internal-reference-number" class="mb-4">
        <template v-slot:header>
          <div class="reference-number">
            <h3>Internal Reference Number</h3>
            <Permissioned section="applications" :id="application.id">
              <PrimaryButton
                permission="edit.internal-reference-number"
                light
                dense
                shrink
                @click.native="editReferenceNumber = true"
              >
                Edit
                <template v-slot:append:icon>
                  <v-icon>mdi-pencil</v-icon>
                </template>
              </PrimaryButton>
            </Permissioned>
          </div>
          <EditInternalReferenceNumber
            :key="application.id"
            :application="application"
            :isOpen="editReferenceNumber"
            @close="editReferenceNumber = false"
          />
        </template>
        {{ application.applicationReference || "-" }}
      </BaseDetails>
      <v-divider permission="compliance-checks" class="mb-3" />
      <ComplianceCheckList
        showHeader
        class="mb-4"
        permission="compliance-checks"
        :checks="application.complianceChecks"
        :references="application.references"
      />
      <v-divider permission="shift-availability" class="mb-3" />
      <CandidateShifts
        class="mb-4"
        permission="shift-availability"
        :application="application"
      />
      <v-divider
        v-if="application.assessments && application.assessments.length"
        class="mb-3"
      />
      <BaseDetails
        class="mb-4"
        title="Assessment"
        permission="assessment"
        v-if="application.assessments && application.assessments.length"
      >
        <AssessmentItem
          v-for="assessment in application.assessments"
          :assessment="assessment"
          :key="assessment.id"
        />
      </BaseDetails>
      <v-divider permission="cv" />
      <CVPreview
        class="mb-4"
        permission="cv"
        v-if="worker.cv"
        :url="worker.cv.url"
        :type="worker.cv.mimeType"
      />
    </Permissioned>
  </div>
</template>

<script>
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import ComplianceCheckList from "@/components/workers/common/ComplianceCheckList";
import CandidateShifts from "@/views/bookings/BookingDetails/components/CandidateDetails/CandidateShifts";
import CVPreview from "@/views/bookings/JobCandidate/components/CVPreview";
import AssessmentItem from "@/views/bookings/Assessment/AssessmentItem";
import EditInternalReferenceNumber from "@/views/bookings/components/ConfirmDialogs/EditInternalReferenceNumber";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import Chip from "@/components/common/Chip";
import Permissioned from "@/components/common/Permissioned";
import EditAwrStatus from "@/views/bookings/components/ConfirmDialogs/EditAwrStatus";

export default {
  name: "WorkerDetails",
  data() {
    return {
      editReferenceNumber: false,
      editAwrStatus: false
    };
  },
  computed: {
    worker() {
      return this.application.user;
    },
    organisation() {
      return this.application.organisation;
    }
  },
  components: {
    EditAwrStatus,
    Permissioned,
    Chip,
    PrimaryButton,
    EditInternalReferenceNumber,
    AssessmentItem,
    CVPreview,
    CandidateShifts,
    ComplianceCheckList,
    BaseDetails
  },
  props: {
    application: Object
  },
  methods: {
    viewWorkerProfile() {
      this.$router.push({
        name: "workerInfo",
        params: { id: this.worker.id }
      });
    }
  }
};
</script>

<style lang="scss">
.submit-worker-details {
  top: 0;
  background-color: $white;

  > div {
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 0;
  }

  .reference-number {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
