<template>
  <div class="organisation-list table-row-pointer">
    <div class="toolbar">
      <BaseSearchBar
        @search="searchKey => searchUsers(searchKey)"
        class="mr-2"
      />
      <Permissioned section="settings">
        <NewUserAction :oneOfPermissions="newUserPermissions" />
      </Permissioned>
    </div>
    <Datatable
      :headers="headers"
      :items="users"
      :is-loading="isFetchingUsers"
      :search="searchText"
      :customFilter="customFilter"
      @onSelect="handleUserSelect"
      hideRowSeparator
      disableFiltering
      disablePagination
    >
      <template v-slot:roles="{ item }">
        {{ getRoles(item.roles) }}
      </template>
      <template v-slot:status="{ item }">
        <div class="d-flex">
          <Chip
            style="margin-right: 0.5em; min-width: 110px"
            :color="chipStatus(item)"
          >
            {{ item.status }}
          </Chip>
          <ResetPassword :user="item" />
          <ResendInvitation
            v-if="showInviteButton(item.status)"
            :user="item"
            @resent="fetchOrganisationUsers(selfOrganisationId)"
          />
        </div>
      </template>
      <template v-slot:pagination>
        <v-pagination
          v-if="showPagination"
          v-model="page.currentPage"
          :length="page.lastPage"
          :total-visible="page.pageSize"
          @input="loadUsers(selfOrganisationId)"
        />
      </template>
    </Datatable>
    <EditUserAction
      v-if="selectedUser"
      :user="selectedUser"
      :is-open="isEditUserDialogOpen"
      @close="isEditUserDialogOpen = false"
    />
  </div>
</template>

<script>
import BaseSearchBar from "@/components/common/BaseSearchBar";
import Datatable from "@/components/common/Datatable";
import { SETTINGS_NAMESPACE } from "@/store/modules/settings";
import { createNamespacedHelpers } from "vuex";
import { FETCH_ORGANISATION_USERS } from "@/store/modules/settings/actions";
import { copyState } from "@/utils/helpers";
import { getFullName, withFullName } from "@/utils/users";
import Chip from "@/components/common/Chip";
import NewUserAction from "@/views/settings/Organisation/Users/actions/NewUser/NewUserAction";
import { AUTH_NAMESPACE } from "@/store/modules/auth";
import EditUserAction from "@/views/settings/Organisation/Users/actions/EditUser/EditUserAction";
import { get, map, startCase } from "lodash";
import Permissioned from "@/components/common/Permissioned";
import { customTableFussyFilter } from "@/utils/filters";
import ResendInvitation from "@/views/settings/Organisation/Users/actions/ResendInvitation";
import { USER_STATUSES } from "@/constants/users";
import { paginationMixin } from "@/mixins/pagination.mixin";
import ResetPassword from "@/views/settings/Organisation/Users/actions/ResetPassword";

const { mapActions, mapState } = createNamespacedHelpers(SETTINGS_NAMESPACE);
const { mapState: mapAuthState } = createNamespacedHelpers(AUTH_NAMESPACE);

const NEW_USER_PERMISSIONS = [
  "new-user",
  "new-user.client",
  "new-user.msp",
  "new-user.agency"
];
const { INACTIVE, INVITED, INVITE_EXPIRED } = USER_STATUSES;

export default {
  name: "OrganisationUsersList",
  mixins: [paginationMixin],
  components: {
    EditUserAction,
    NewUserAction,
    Chip,
    Datatable,
    BaseSearchBar,
    Permissioned,
    ResendInvitation,
    ResetPassword
  },
  data() {
    return {
      newUserPermissions: NEW_USER_PERMISSIONS,
      searchText: "",
      isEditUserDialogOpen: false,
      selectedUser: {}
    };
  },
  computed: {
    ...mapState(copyState(["organisationUsers", "isFetchingUsers"])),
    ...mapAuthState(copyState(["userData"])),
    users() {
      return this.organisationUsers.map(withFullName);
    },

    headers() {
      return [
        {
          text: "Name",
          value: "fullName"
        },
        {
          text: "Role(s)",
          value: "roles"
        },
        {
          text: "Status",
          value: "status"
        }
      ];
    },
    searchKeys() {
      return map(this.headers, "value");
    },
    selfOrganisationId() {
      return get(this.userData, "organisation.id");
    }
  },
  created() {
    if (this.selfOrganisationId) {
      this.loadUsers(this.selfOrganisationId);
    }
  },
  methods: {
    ...mapActions({
      fetchOrganisationUsers: FETCH_ORGANISATION_USERS
    }),
    chipStatus(item) {
      return item.status === "inactive" ? "red" : "green";
    },
    getFullName,
    getRoles(roles) {
      roles = roles.map(role => startCase(role));
      const rolesData = roles.join(", ");

      return rolesData;
    },
    handleUserSelect(user) {
      this.selectedUser = user;
      this.isEditUserDialogOpen = true;
    },
    customFilter(value, searchValue, item) {
      return customTableFussyFilter(searchValue, item, this.searchKeys);
    },
    showInviteButton(status) {
      return (
        status === INVITED || status === INVITE_EXPIRED || status === INACTIVE
      );
    },
    async loadUsers(id) {
      const filter = { organisation_id: id };
      const { meta } = await this.fetchOrganisationUsers({
        filter,
        ...this.requestPageParams
      });
      this.updatePageDetails(meta);
    },
    async searchUsers(searchKey) {
      if (searchKey) {
        const filter = {
          organisation_id: this.selfOrganisationId,
          search: searchKey
        };
        const { meta } = await this.fetchOrganisationUsers({
          filter,
          ...this.requestPageParams
        });
        this.updatePageDetails(meta);
        return;
      }
      this.loadUsers(this.selfOrganisationId);
    }
  },
  watch: {
    selfOrganisationId(organisationId) {
      this.loadUsers(organisationId);
    }
  }
};
</script>

<style lang="scss"></style>
