<template>
  <div>
    <div v-if="isLoading">
      <v-skeleton-loader v-for="i in 10" :key="i" type="list-item" />
    </div>
    <BaseDetails class="shift-availability-section" v-else-if="showComponent">
      <template v-slot:header>
        <h3>Shifts</h3>
      </template>
      <template v-slot:body>
        <div v-for="(shifts, key) in groupedShifts" :key="key" class="my-3">
          <h4>{{ key | startCase }}</h4>
          <div
            class="d-flex align-center"
            v-for="shift in shifts"
            :key="shift.id"
          >
            <inline-svg
              width="30"
              :src="require('@/assets/svg/shift-icon-black.svg')"
              class="mr-2 my-1"
            />
            {{ shift.startDate | shiftDate }} -
            {{ shift.startDate | shiftHours }} -
            {{ shift.endDate | shiftHours }}
          </div>
        </div>
      </template>
    </BaseDetails>
  </div>
</template>

<script>
import BaseDetails from "@/components/common/BaseDetails/BaseDetails";
import { createNamespacedHelpers } from "vuex";
import { FETCH_APPLICATION_SHIFTS } from "@/store/modules/applications/actions";
import { APPLICATION_NAMESPACE } from "@/store/modules/applications";
import { IS_LOADING_APPLICATION_SHIFTS } from "@/store/modules/applications/getters";
import { map } from "lodash";
import InlineSvg from "vue-inline-svg";
import { groupByShiftStatus } from "@/utils/shifts";

const { mapActions, mapGetters } = createNamespacedHelpers(
  APPLICATION_NAMESPACE
);

export default {
  components: { BaseDetails, InlineSvg },
  data() {
    return {
      isCandidateShiftsModalOpen: false,
      page: {
        pageSize: 10,
        currentPage: 1
      },
      shifts: []
    };
  },
  created() {
    this.loadApplicationShifts();
  },
  props: {
    application: Object
  },
  computed: {
    ...mapGetters({
      isLoading: IS_LOADING_APPLICATION_SHIFTS
    }),
    showComponent() {
      return this.shifts && this.shifts.length;
    },
    groupedShifts() {
      return groupByShiftStatus(this.shifts);
    }
  },
  methods: {
    ...mapActions({
      fetchApplicationShifts: FETCH_APPLICATION_SHIFTS
    }),
    async loadApplicationShifts() {
      const { data } = await this.fetchApplicationShifts({
        applicationId: this.application.id,
        params: this.getRequestParams()
      });
      this.shifts = map(data, shift => ({
        ...shift.shift,
        ...shift
      }));
    },
    getRequestParams() {
      return {
        "page[size]": this.page.pageSize,
        "page[number]": this.page.currentPage
      };
    }
  }
};
</script>

<style lang="scss"></style>
