import { POST_GLOBAL_MESSAGE } from "@/store/modules/global/action-types";
import { map, get, isArray } from "lodash";
import { HTTP_UNAUTHORIZED_MESSAGE } from "@/utils/requestUtils";

export const addGlobalMessage = (dispatch, { type, meta }) => {
  if (!meta) {
    meta = { title: type === "error" ? "Action failed" : "Action successful" };
    console.warn("No human readable message returned from backend");
  } else if (meta.title === HTTP_UNAUTHORIZED_MESSAGE) {
    return;
  }

  let message = {
    snackbar: true,
    type,
    title: meta.title,
    message: meta.body ? meta.body : meta.detail
  };
  dispatch(POST_GLOBAL_MESSAGE, message, { root: true });
};

export const handleWithErrorMessage = async ({
  request,
  dispatch,
  setLoadingFunction = () => {},
  setDataFunction = () => {}
}) => {
  try {
    setLoadingFunction(true);
    const response = await request();
    setDataFunction(response);
    return response;
  } catch (e) {
    errorHandler(dispatch, e);
    throw e;
  } finally {
    setLoadingFunction(false);
  }
};

export const handleWithMessageAndLoading = async ({
  request,
  dispatch,
  setLoadingFunction,
  setDataFunction
}) => {
  try {
    setLoadingFunction(true);
    const { data, meta } = await request();
    if (setDataFunction) {
      setDataFunction(data);
    }
    addGlobalMessage(dispatch, { type: "success", meta });
    return { data, meta };
  } catch (e) {
    errorHandler(dispatch, e);
    throw e;
  } finally {
    setLoadingFunction(false);
  }
};

export const handleWithGlobalMessage = async (request, dispatch) => {
  try {
    const { data, meta } = await request();
    addGlobalMessage(dispatch, { type: "success", meta });
    return { data, meta };
  } catch (e) {
    errorHandler(dispatch, e);
    throw e;
  }
};

// Disable pluralize is needed when devour library automatically pluralizes words.
// So making call /bookings/1/accept is transformed into /bookings/1/acceptS
// These two functions prevent that behaviour
export const disablePluralizeForCall = (api, call) => {
  const actualPluralize = api.pluralize;
  api.pluralize = word => word;
  call();
  api.pluralize = actualPluralize;
};

export const disablePluralizeAsync = async (api, call) => {
  const actualPluralize = api.pluralize;
  api.pluralize = word => word;
  api.pluralize.singular = word => word;
  const response = await call();
  api.pluralize = actualPluralize;
  return response;
};

export const errorHandler = (dispatch, exception) => {
  let errors =
    get(exception, "errors") ||
    get(exception, "response.data.errors") ||
    get(exception, "response.data.meta.title") ||
    get(exception, "message");

  if (isArray(errors)) {
    map(errors, error => {
      addGlobalMessage(dispatch, {
        type: "error",
        meta: { title: error.title, detail: error.detail || null }
      });

      throw new Error(error.status);
    });
  } else {
    addGlobalMessage(dispatch, {
      type: "error",
      meta: { title: errors.message ?? errors, detail: null }
    });
  }

  throw { status: 404 };
};

export const getToken = () => {
  // TODO check current envoirement url instead of localhost
  if (window.location.href.indexOf("localhost") > -1) {
    return localStorage.getItem("token");
  }
  // TODO should be replaced by cookie token
  // return Cookies.get("token");
  return localStorage.getItem("token");
};

export const isNotTestingEnv = () => {
  return process.env.NODE_ENV !== "test";
};

export const getImpersonateUserId = () => {
  return JSON.parse(localStorage.getItem("impersonateUserId"));
};

export const getSelectedProfile = () => {
  const selectedProfile = localStorage.getItem("selectedProfile");
  return selectedProfile === "undefined" || selectedProfile === null
    ? null
    : JSON.parse(selectedProfile);
};
