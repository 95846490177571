<template>
  <BaseAction>
    <ResendInvitationDialog
      v-if="isDialogOpen"
      @close="isDialogOpen = false"
      @resent="$emit('resent')"
      :user="user"
    />
    <PrimaryButton
      @click.native.stop="isDialogOpen = true"
      class="resend-button"
      text
      dense
    >
      Resend Invite
      <v-icon class="ml-2">
        mdi-send
      </v-icon>
    </PrimaryButton>
  </BaseAction>
</template>

<script>
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ResendInvitationDialog from "@/views/settings/Organisation/Users/dialogs/ResendInvitationDialog";
import BaseAction from "@/components/common/BaseAction";

export default {
  name: "ResendInvitation",
  components: { ResendInvitationDialog, PrimaryButton, BaseAction },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isDialogOpen: false
    };
  }
};
</script>

<style lang="scss">
button.base-button.base-primary-button.v-btn.v-btn--flat.v-btn--text.primary--text.resend-button {
  padding: 0 5px !important;
}
</style>
